<template>
  <section ref="adBannerRef" :style="adImageUrl ? 'height: 380px;' : 'height: 0;'">
    <div v-if="adImageUrl" class="sections-ad-bar" :style="`padding: 0 !important; ${ad.adDestinationUrl ? 'cursor: pointer;' : ''}`" @click="triggerAnalyticsClickEvent">
      <div class="background" :style="`background: url(${adImageUrl})`" />
      <div class="background" :style="`background: url(${adImageUrl})`" />
      <div class="sections-ad-bar-wrapper">
        <div class="__top-corner-label">
          Ad
        </div>
        <img v-lazy-load  alt="Ad banner" :data-src="adImageUrl">
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">


const props = defineProps({
  adDisplayLocation: {
    type: [Object, null] as PropType<AdDisplayLocation>,
    default: null,
  },
});

// DATA Definitions
// ---------------------------------------------------------------------------------------------------------------------
import {Ref} from 'vue/dist/vue';
import {AdDisplayLocation, Listing} from '~/@types/listing';

const {$ad} = useNuxtApp();
const adImageUrl: Ref<string | null> = ref(null);
const isSectionVisible: Ref<boolean> = ref(false);
let observer: Ref<IntersectionObserver | null> = ref(null);
const adBannerRef: Ref<HTMLElement | null> = ref(null);
const {$analyticsTrack} = useNuxtApp();
const ad: Ref<Listing | null> = ref(null);

onMounted(() => {
  fetchAd();
  initViewTracker();
  initViewTracker(true);
});

/**
 * Fetch ad
 */
async function fetchAd () {
  try {
    const response = await $ad.search([props.adDisplayLocation]);
    if (response?.hits && response.hits.length) {
      // get a random ad from the list
      const randomAdIndex = Math.floor(Math.random() * response.hits.length);
      ad.value = response.hits[randomAdIndex].document as Listing;
      adImageUrl.value = response.hits[randomAdIndex].document?.adOtherImageUrls?.bannerImgUrl ?? null;
      initViewTracker(true);
    }
  } catch (error: any) {
    console.log(error);
  }
}

/**
 * Init view tracker
 *
 * @param forceRefresh
 */
function initViewTracker (forceRefresh = false) {
  if (forceRefresh) {
    isSectionVisible.value = false;
    // Stop observing the element when the component is unmounted
    if (adBannerRef.value && observer.value) {
      observer.value.unobserve(adBannerRef.value);
    }
  }
  // Only proceed if adBannerRef exists and Observer is supported
  if (adBannerRef.value && 'IntersectionObserver' in window) {
    observer.value = new IntersectionObserver((entries) => {
      // entries[0] is this component
      if (entries[0].isIntersecting && !isSectionVisible.value) {
        triggerViewTracking();
        isSectionVisible.value = true;
      }
    }, {
      threshold: 0.8, // adjust this value to control when the observer callback should be fired
    });

    // Start observing the element
    observer.value.observe(adBannerRef.value);
  }
}

/**
 * @_BEFORE_UNMOUNT Hook
 */
onBeforeUnmount(() => {
  // Stop observing the element when the component is unmounted
  if (adBannerRef.value && observer.value) {
    observer.value.unobserve(adBannerRef.value);
  }
});

/**
 * Check whether this section inside the view or not
 */
function triggerViewTracking () {
  if (!ad.value) return;
  // Track analytics event
  $analyticsTrack({
    action: 'view',
    entity: 'Listing',
    property: 'listing',
    entitySlug: ad.value?.slug,
    displayedLocation: props.adDisplayLocation,
    eventData: {
      userIntention: 'passive',
      component: 'listing widget',
      searchResultId: 'ad',
      position: 1,
    },
  });
  if (adBannerRef.value && observer.value) {
    observer.value.unobserve(adBannerRef.value);
  }
}

/**
 * Trigger analytics click event
 */
function triggerAnalyticsClickEvent () {
  if (!ad.value) return;
  // Track analytics event
  $analyticsTrack({
    action: 'click',
    entity: 'Listing',
    property:'listing',
    entitySlug: ad.value.slug,
    displayedLocation: props.adDisplayLocation,
    eventData: {
      userIntention: 'passive',
      component: 'listing widget',
      searchResultId: 'ad',
      position: 1,
    },
  });

  // Open the ad in a new tab
  window.open(ad.value.adDestinationUrl, '_blank');
}
</script>

<style lang="scss" scoped>
section {
  overflow: hidden;
  width: 100%;
  padding: 0 !important;

  .sections-ad-bar {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .background {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-size: cover;
      background-position: center;
    }

    .sections-ad-bar-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      backdrop-filter: blur(30px);

      .__top-corner-label {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        background: rgb(var(--accent));
        padding: 4px 12px;
        width: fit-content;
        height: fit-content;
        border-bottom-right-radius: 5px;
        box-shadow: 1px 1px 2px #00000078;
        color: #fff;
        font-size: 12px;
      }

      img {
        width: 100%; // Width is 100% of the container
        height: 100%; // Height is 100% of the container
        object-fit: contain; // Maintain aspect ratio and fit within the element
        max-height: 100%; // Ensure it doesn't exceed the container height
        max-width: 100%; // Ensure it doesn't exceed the container width
      }
    }
  }
}
</style>
